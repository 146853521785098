import React from 'react'

const AboutBanner = () => {
  return (
    // <div className=''>
    //   <div className="relative flex  items-center ">
    //     <img
    //      src="./images/About.png"
    //       alt="swiper"
    //       className="w-full md:h-full h-[250px]"
    //     />
    //     <div className="absolute left-4 sm:left-10 text-white text-xl ">
    //       <div className="text-2xl sm:text-3xl lg:text-5xl lg:space-y-2">
    //         <div className="">
    //           <h1 className="text-[50px] font-bold mb-6 leading-[52px] ">About Us</h1>
    //           <hr class="border-t-4 border-gray-300 my-4"></hr>
    //           <p className="text-[36px]  mb-8 leading-[54px] font-bold mt-20">
    //             <p>Discover</p> 
    //             <p>Excellence in</p> 
    //             <p>Industrial Services</p>
    //             <p>with <span className='text-[#ffa700]'>Emech</span>"</p>  
    //           </p>
    //           <div>
    //           <button className='bg-white py-1 px-5 text-[18px] rounded-full text-black'>
    //             <div className='flex gap-x-3'> 
    //             <div className='flex flex-col justify-center'>
    //             <p>Lets Discuss More </p>
    //             </div>
                  
    //               <img src="./images/trend.png" alt="" />
    //             </div>
                  
    //           </button>

    //           </div>
              
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
      <div className='grid sm:grid-cols-5'>
        <div className='bg-black sm:col-span-2 flex flex-col justify-center text-center sm:text-start py-10'>
          <div className='px-5 lg:px-0 lg:pl-7 xl:pl-10 text-white'>
          <div className="text-2xl sm:text-3xl lg:text-5xl lg:space-y-2">
             <div className="">
               <h1 className="text-[37px] leading-[40px] lg:text-[50px] font-bold mb-6 lg:leading-[52px] ">About Us</h1>
               <hr class="border-t-4 border-gray-300 my-4 mx-10 sm:mx-0 sm:mr-16"></hr>
               <p className="text-[36px]  mb-8 leading-[54px] font-bold lg:mt-20">
                 <p>Discover</p> 
                 <p>Excellence in</p> 
                 <p>Industrial Services</p>
                 <p>with <span className='text-[#ffa700]'>Emech</span>"</p>  
               </p>
               <div>

               </div>
              
             </div>
           </div>
          </div>

        </div>
        <div className='sm:col-span-3 -order-1 sm:order-1'>
          <img src="./images/About.jpg" className='h-full' alt="" />
        </div>

      </div>

    </div>
  )
}

export default AboutBanner
