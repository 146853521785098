import React from "react";


const HeaderAbout = () => {

  

  return (
    <div>
      <div className="relative flex  items-center ">
        <img
         src="./images/image1.png"
          alt="swiper"
          className="w-full md:h-full h-[250px]"
        />
        <div className="absolute left-4 sm:left-10 text-white text-xl font-bold">
          <div className="text-2xl sm:text-3xl lg:text-5xl lg:space-y-2">
            <div className="w-1/2">
              <h1 className="text-5xl font-medium mb-6">About Emech</h1>
              <p className="text-xl mb-8">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                viverra euismod odio, gravida pellentesque urna varius vitae.
              </p>
              <button className="overflow-hidden w-32 p-2 h-12 bg-[#F19A37] text-white border-none rounded-md text-xl font-bold cursor-pointer relative z-10 group">
                Contact Us
                <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span className="absolute w-36 h-32 -top-8 -left-2 bg-indigo-400 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span className="absolute w-36 h-32 -top-8 -left-2 bg-indigo-600 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-8 z-10 ">
                  N O W
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderAbout;
