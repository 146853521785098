import React from 'react'

const HomePageBanner = () => {
  return (
    // <div className="relative flex  items-center ">
    //     <img
    //      src="./images/Home.png"
    //       alt="swiper"
    //       className="w-full md:h-full h-[250px]"
    //     />
    //     <div className="absolute left-4 sm:left-10 text-white text-xl ">
    //       <div className="text-2xl sm:text-3xl lg:text-5xl lg:space-y-2">
    //         <div className="lg:w-2/3 xl:w-3/4">
    //           <h1 className="text-[30px] lg:text-[35px] font-bold mb-6 lg:leading-[52px] w-2/3">"Powering Industries with Expertise:</h1>
    //           <p className="text-[27px]  mb-8 leading-[40px]">
    //             <p>Your Trusted Partner in</p> 
    //             <p><span className='text-[#ffa700]'>Generator</span> Repair,</p> 
    //             <p><span className='text-[#ffa700]'>Air Compressor</span> Maintenance,</p>
    //             <p>and <span className='text-[#ffa700]'>CCTV Security Solutions</span>"</p>  
    //           </p>
    //           <div>
    //           <button className='bg-white py-1 px-5 text-[18px] rounded-full text-black'>
    //             <div className='flex gap-x-3'> 
    //             <div className='flex flex-col justify-center'>
    //             <p>Lets Discuss More </p>
    //             </div>
                  
    //               <img src="./images/trend.png" alt="" />
    //             </div>
                  
    //           </button>

    //           </div>
              
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    <div>
      <div className='grid sm:grid-cols-5'>
        <div className='bg-black sm:col-span-2 flex flex-col justify-center text-center sm:text-start py-10'>
          <div className='px-5 lg:px-0 lg:pl-7 xl:pl-10 text-white'>
          <h1 className="text-[35px] sm:text-[25px] lg:text-[35px] font-bold mb-6 lg:leading-[52px]  ">"Powering Industries with Expertise:</h1>
               <p className="text-[27px] sm:text-[20px] lg:text-[27px]  mb-8 leading-[40px] sm:leading-[30px] lg:leading-[40px]">
                 <p>Your Trusted Partner in</p> 
                 <p><span className='text-[#ffa700]'>Generator</span> Repair,</p> 
                 <p><span className='text-[#ffa700]'>Air Compressor</span> Maintenance,</p>
                 <p>and <span className='text-[#ffa700]'>CCTV Security Solutions</span>"</p>  
              </p>
              <button className='bg-white py-1 px-5 text-[18px] rounded-full text-black'>
                <div className='flex gap-x-3'> 
               <div className='flex flex-col justify-center'>
                <p>Lets Discuss More </p>
                </div>
                  
                  <img src="./images/trend.png" alt="" />
                </div>
                  
              </button>
          </div>

        </div>
        <div className='sm:col-span-3 -order-1 sm:order-1'>
          <img src="./images/Cut.png" alt="" />
        </div>

      </div>

    </div>
  )
}

export default HomePageBanner
