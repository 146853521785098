

import Navbar from './components/Navbar';
import HeaderIndex from './components/HeaderIndex';
import HeaderContact from './components/HeaderContact';
import Newsletter from './components/Newsletter';
import Numbers from './components/Numbers';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer'
import ContactBar from './components/ContactBar'
import Gallery from './components/Gallery'
import Video from './components/Video'
import Services from './components/Services'
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import HeaderServices from './components/HeaderServices';
import ServiceContent from './components/ServiceContent';
import WeOffer from './components/WeOffer';
import HeaderAbout from './components/HeaderAbout';
import Patnership from './components/Patnership';
import Featuers from './components/Featuers';


function App() {
  return (<>
    <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route path='/' element={
          <>
          <HeaderIndex/>
          </>
        } />
        <Route path="about" element={
          <>
            <HeaderAbout />
            <Services/>
            <Featuers/>
            <Patnership/>
          
            </>
          } />
          <Route path="services" element={
          <div className='overflow-x-hidden'>
            <HeaderServices />
            <WeOffer/> 
            <Gallery/>
            <ServiceContent/>
            <Video/>
           
          </div>
        } />
          <Route path="contact" element={
            <>
              <HeaderContact />
              <ContactBar/>
              <ContactForm/>
              
            </>
          } />
      </Routes>
    </BrowserRouter>  
  <Newsletter/>
  <Footer/>

        </>
  );
}

export default App;
