import React from "react";

const ServiceContent = () => {
  return (
    <section className="w-full mx-auto py-20 bg-gray-100 my-4 ">
      <div className="w-fit pb-1 px-2 mx-4 rounded-md text-2xl font-semibold border-b-2 border-blue-600 dark:border-b-2 dark:border-yellow-600">
        Our Services
      </div>

      <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex flex-col md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
        <div className="lg:w-[35%] grid grid-cols-3 gap-x-3 xs:w-full bg-gray-200 p-2">
          <div className="flex flex-col justify-center">
            <img className="" src="./images/oil1.png" alt="billboard image" />
            <img
              className="mt-5"
              src="./images/lubricants.png"
              alt="billboard image"
            />
          </div>
          <div className="flex flex-col justify-center col-span-2">
            <img className="" src="./images/grease.png" alt="billboard image" />
          </div>
        </div>
        <div className="lg:w-[37%] sm:w-full xs:w-full bg-gray-100    md:p-4 xs:p-0 rounded-md">
          <h2 className="text-3xl font-semibold text-gray-900  dark:text-gray-800">
            Lubricants
          </h2>
          <p className="text-md mt-4">
            E Mech provides high-quality lubricants for generators, air
            compressors and other electromechanical equipment. Our lubricants
            are specially formulated to meet the demanding requirements of your
            equipment, ensuring optimal performance, reliability and longevity.
            Trust E Mech to keep your equipment running smoothly with our
            premium lubricant products.
          </p>
        </div>
      </div>

      <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex flex-col md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
        <div className="lg:w-[37%] sm:w-full xs:w-full bg-gray-100    md:p-4 xs:p-0 rounded-md">
          <h2 className="text-3xl font-semibold text-gray-900  dark:text-gray-800">
            COmssinioning
          </h2>
          <p className="text-md mt-4">
            E Mech offers professional commissioning and installation services
            for generators, air compressors, and other electromechanical
            equipment. Our team of certified technicians will ensure that your
            equipment is installed correctly, configured and tested to operate
            at peak efficiency. Trust E Mech for hassle-free installation and
            commissioning of your electromechanical equipment..
          </p>
        </div>
        <div className="lg:w-[35%] grid grid-cols-3 gap-x-3 xs:w-full bg-gray-200 p-2">
          <div className="flex flex-col justify-center col-span-2">
            <img className="" src="./images/C3.png" alt="billboard image" />
          </div>
          <div className="flex flex-col justify-center">
            <img className="" src="./images/C1.png" alt="billboard image" />
            <img className="mt-5" src="./images/C2.png" alt="billboard image" />
          </div>
        </div>
      </div>

      <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex flex-col md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
        <div className="lg:w-[35%] grid grid-cols-3 gap-x-3 xs:w-full bg-gray-200 p-2">
          <div className="flex flex-col justify-center">
            <img className="" src="./images/C3.png" alt="billboard image" />
            <img className="mt-5" src="./images/C2.png" alt="billboard image" />
          </div>
          <div className="flex flex-col justify-center col-span-2">
            <img className="" src="./images/C2.png" alt="billboard image" />
          </div>
        </div>
        <div className="lg:w-[37%] sm:w-full xs:w-full bg-gray-100    md:p-4 xs:p-0 rounded-md">
          <h2 className="text-3xl font-semibold text-gray-900  dark:text-gray-800">
            Air Compressor
          </h2>
          <p className="text-md mt-4">
            E Mech offers comprehensive air compressor services, including
            maintenance, repair, and installation. Our team of certified
            technicians are equipped to handle all types of compressors,
            ensuring that your equipment is always in top working condition for
            optimal performance
          </p>
        </div>
      </div>

      <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex flex-col md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
        <div className="lg:w-[37%] sm:w-full xs:w-full bg-gray-100    md:p-4 xs:p-0 rounded-md">
          <h2 className="text-3xl font-semibold text-gray-900  dark:text-gray-800">
            Generator
          </h2>
          <p className="text-md mt-4">
            E Mech offers expert generator operation and maintenance services to
            keep your power system running smoothly. Our team of certified
            technicians are equipped to handle all types of generator sets,
            ensuring that your equipment is always in top working condition.
          </p>
        </div>
        <div className="lg:w-[35%] grid grid-cols-3 gap-x-3 xs:w-full bg-gray-200 p-2">
          <div className="flex flex-col justify-center col-span-2">
            <img className="" src="./images/gen1.png" alt="billboard image" />
          </div>
          <div className="flex flex-col justify-center">
            <img className="" src="./images/G1.png" alt="billboard image" />
            <img className="mt-5" src="./images/G2.png" alt="billboard image" />
          </div>
        </div>
      </div>

      <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex flex-col md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
        <div className="lg:w-[35%] grid grid-cols-3 gap-x-3 xs:w-full bg-gray-200 p-2">
          <div className="flex flex-col justify-center">
            <img className="" src="./images/oil1.png" alt="billboard image" />
            <img
              className="mt-5"
              src="./images/lubricants.png"
              alt="billboard image"
            />
          </div>
          <div className="flex flex-col justify-center col-span-2">
            <img className="" src="./images/grease.png" alt="billboard image" />
          </div>
        </div>
        <div className="lg:w-[37%] sm:w-full xs:w-full bg-gray-100    md:p-4 xs:p-0 rounded-md">
          <h2 className="text-3xl font-semibold text-gray-900  dark:text-gray-800">
            Operation & Maintennce
          </h2>
          <p className="text-md mt-4">
            E Mech offers expert operation and maintenance services for
            generators and air compressors to keep your power system running
            smoothly. Our team of certified technicians are equipped to handle
            all types of equipment, ensuring that your equipment is always in
            top working condition for optimal performance and reliability.
          </p>
        </div>
      </div>

      <div className="xl:w-[80%] sm:w-[85%] xs:w-[90%] mx-auto flex flex-col md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-4">
        <div className="lg:w-[37%] sm:w-full xs:w-full bg-gray-100    md:p-4 xs:p-0 rounded-md">
          <h2 className="text-3xl font-semibold text-gray-900  dark:text-gray-800">
            Spare Parts
          </h2>
          <p className="text-md mt-4">
            E Mech provides a wide range of products, spare parts, and services
            for generators, air compressors and other electromechanical
            equipment. Our team of experts can help you with everything from
            product selection and installation to maintenance, repair and spare
            parts supply. Trust E Mech for all your electromechanical needs.
          </p>
        </div>
        <div className="lg:w-[35%] grid grid-cols-3 gap-x-3 xs:w-full bg-gray-200 p-2">
          <div className="flex flex-col justify-center col-span-2">
            <img className="" src="./images/C3.png" alt="billboard image" />
          </div>
          <div className="flex flex-col justify-center">
            <img className="" src="./images/C1.png" alt="billboard image" />
            <img className="mt-5" src="./images/C2.png" alt="billboard image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceContent;
