import React from "react";
import { GiPowerGenerator } from "react-icons/gi";
import { SiCopaairlines } from "react-icons/si";
import { LiaToolsSolid } from "react-icons/lia";
import { HiMiniCubeTransparent } from "react-icons/hi2";



const WeOffer = () => {
  return (
    <section className="pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <span className="mb-2 block text-xl font-semibold text-primary">
                Our Services
              </span>
              <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark text-[#f19a37] sm:text-4xl md:text-[40px]">
                What We Offer
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
              At E Mech, we strive to provide our customers with a range of benefits when they choose us for their electromechanical services needs
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <ServiceCard
            title="Generators"
            details="We offers expert generator operation and maintenance services to keep your power system running smoothly."
            icon={<GiPowerGenerator  className="w-16 h-16"/>}
          />
          <ServiceCard
            title="Air compressor"
            details="We offers comprehensive air compressor services, including maintenance, repair, and installation"
            icon={<SiCopaairlines className="w-16 h-16"/>}

          />
          <ServiceCard
            title="Operation and Maintenance "
            details="We offers expert operation and maintenance services for generators and air compressors to keep your power system running smoothly."
            icon={<LiaToolsSolid className="w-16 h-16"/>}
          />
          <ServiceCard
            title="Spare parts"
            details="E Mech provides a wide range of products, spare parts, and services for generators, air compressors and other electromechanical equipment. "
            icon={<HiMiniCubeTransparent className="w-16 h-16"/>}
          />
          <ServiceCard
            title="COmssinioing"
            details="E Mech offers professional commissioning and installation services for generators, air compressors, and other electromechanical equipment. "
            icon={<HiMiniCubeTransparent className="w-16 h-16"/>}
          />
          <ServiceCard
            title="Lubricants"
            details="E Mech provides high-quality lubricants for generators, air compressors and other electromechanical equipment. "
            icon={<HiMiniCubeTransparent className="w-16 h-16"/>}
          />
        </div>
      </div>
    </section>
  );
};

export default WeOffer;

const ServiceCard = ({ icon, title, details }) => {
  return (
    <>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div className="mb-9 rounded-[20px]  p-10 shadow-2 hover:shadow-lg dark:bg-dark-2 md:px-7 xl:px-10">
          <div className="mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
            {icon}
          </div>
          <h4 className="mb-[14px] text-2xl font-semibold text-dark ">
            {title}
          </h4>
          <p className="text-body-color dark:text-dark-6">{details}</p>
        </div>
      </div>
    </>
  );
};