import React from 'react'

const HomeWhoarewe = () => {
  return (
    <div className='mt-32 '>
      <div className='grid lg:grid-cols-2 gap-x-20  px-4 sm:px-20'>
        <div className='flex flex-col justify-center'>
            <div>
                <h1 className='text-[36px] font-bold mt-10 lg:mt-0'>Who we are!</h1>
                <p className='text-justify leading-7 text-[20px] lg:mt-16 mt-5 '>Our company is a leading provider of comprehensive industrial solutions, specializing in air compressor and generator sales, services, and spare parts. We also offer high-quality lubricants, including oils and greases, as well as essential fire and safety tools and equipment. Additionally, we provide advanced security systems, including industrial-scale CCTV, to ensure the safety and security of your facilities. Our commitment to excellence and industry expertise makes us the trusted partner for all your industrial needs.</p>
            </div>
        </div>
        <div className='-order-1 lg:order-1'>
            <img src="./images/Man.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default HomeWhoarewe
