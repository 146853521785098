import React from 'react'
import { Typewriter } from 'react-simple-typewriter'
import HomePageBanner from './HomePageBanner'
import HomeWhoarewe from './HomeWhoarewe'
import Patnership from './Patnership'
import AboutBanner from './AboutBanner'
import HomeWork from './HomeWork'
const HeaderIndex = () => {
  return (

<>
<HomePageBanner/>
    <HomeWhoarewe/>
    <Patnership/>
    <AboutBanner/>
    <HomeWork/>
</>
    // <div className="bg-white dark:bg-gray-800 flex  z-20 items-center overflow-hidden">
    //     <div className="container  flex-wrap sm:items-center justify-center mx-auto px-6 flex  py-16">
    //         <div className="sm:w-2/3 lg:w-2/5 flex flex-col  z-20">
    //             <span className="w-20 h-2  bg-[#f19a37]  mb-12">
    //             </span>
    //             <h1 className="font-bebas-neue uppercase text-6xl sm:text-8xl font-black flex flex-col leading-none dark:text-white text-gray-800">
    //                 Emech
    //                 <span className="text-3xl sm:text-4xl mt-5 lg:mt-10">
    //                     Your one-stop shop for &nbsp;
    //                 </span>
    //                 <span className="text-3xl mt-2  text-[#f19a37]">
    //                     <Typewriter
    //                         words={['generator operation', 'maintenance', 'air compressor services', 'Products!']}
    //                         loop={false}
    //                         cursor
    //                         cursorStyle='_'
    //                         typeSpeed={100}
    //                         deleteSpeed={80}
    //                         delaySpeed={1000}
    //                     />
    //                 </span>
    //             </h1>

    //             {/* <p className="text-sm sm:text-base mt-5 text-gray-700 dark:text-white">
    //                 Dimension of reality that makes change possible and understandable. 
    //             </p> */}
    //             <div className="flex mt-8">
    //                 <a href="#" className="uppercase py-2 px-4 rounded-lg bg-[#f19a37] border-2 border-transparent text-white text-md mr-4 hover:bg-[#fae14b]">
    //                     Services
    //                 </a>
    //                 <a href="#" className="uppercase py-2 px-4 rounded-lg bg-transparent border-2 border-[#f19a37] text-[#f19a37] dark:text-white hover:bg-[#fae14b] hover:text-white text-md">
    //                     Contact us
    //                 </a>
    //             </div>
    //         </div>
    //         <div className="  sm:w-1/3 lg:w-3/5 ">
    //             <img src="/images/hero.webp" className="max-w-lg md:max-w-md m-auto"/>
    //             {/* <img src="/images/hero1.png" className="max-w-lg md:max-w-md m-auto"/> */}

    //         </div>
    //     </div>
    // </div>
    

  )
}

export default HeaderIndex