import React from 'react'

const HeaderContact = () => {
  return (
    <div className="items-center w-10/12 grid-cols-2 mx-auto overflow-x-hidden lg:grid md:py-14 lg:py-24 xl:py-14 lg:mt-3 xl:mt-5" data-aos="fade-right" data-aos-duration="800">
        <div className="pr-2 md:mb-14 py-14 md:py-0">
            <h1 className="text-5xl font-semibold text-[#f19a37] xl:text-7xl "><span className="block w-full">Contact Us</span></h1>
            <p className="py-4 text-2xl text-gray-500 2xl:py-8 md:py-6 2xl:pr-5">
                EAt E Mech, customer satisfaction is our top priority. For any concerns feel free ro contact us .....
            </p>

                    <a href="#" className="uppercase py-2 px-4 rounded-lg bg-[#f19a37] border-2 border-transparent text-white text-lg mr-4 hover:bg-[#fae14b]">
                        Services
                    </a>

        </div>

        <div className="pb-10 overflow-hidden md:p-10 lg:p-0 sm:pb-0">
            <img id="heroImg1" className="transition-all duration-300 ease-in-out hover:scale-105 lg:w-full sm:mx-auto sm:w-4/6 sm:pb-12 lg:pb-0" src="/images/contacthero.png" alt="Awesome hero page image" width="500" height="488"/>
        </div>
    </div>
  )
}

export default HeaderContact