import React from 'react'

const HomeWork = () => {
  return (
    <div>
      <div className="bg-[#9b9b9b] p-4 py-32">
    <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
     
      <div
        className=" grid divide-x-2 divide-y-2  divide-gray-700 overflow-hidden  rounded-3xl border text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4  lg:divide-y-0 xl:grid-cols-4">
        <div className="group relative bg-[#1f2937] transition ">
          <div className="relative  py-12 p-8 text-center">
            <div className='flex justify-center'><img src="./images/AitCom.png" loading="lazy" className=" rounded-full" /></div>
            <p className="text-gray-300 text-[22px]">Air Compressor Sales Services and Spare</p>
          </div>
        </div>
        <div className="group relative bg-[#1f2937] transition ">
          <div className="relative  py-12 p-8 text-center">
            <div className='flex justify-center'><img src="./images/Gen.png" loading="lazy" className=" rounded-full" /></div>
            <p className="text-gray-300 text-[22px]">Generator Sales Services and Spare</p>
          </div>
        </div>
        <div className="group relative bg-[#1f2937] transition ">
          <div className="relative  py-12 p-8 text-center">
            <div className='flex justify-center'><img src="./images/Lub.png" loading="lazy" className=" rounded-full" /></div>
            <p className="text-gray-300 text-[22px]">Lubricants Oil And Greases</p>
          </div>
        </div>
        <div className="group relative bg-[#1f2937] transition ">
          <div className="relative  py-12 p-8 text-center">
            <div className='flex justify-center'><img src="./images/Fire.png" loading="lazy" className=" rounded-full" /></div>
            <p className="text-gray-300 text-[22px] mt-5">Fire and Safety Tools Equipment</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default HomeWork
