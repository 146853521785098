import React from 'react'
import { FaFacebookF } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";



const Navbar = () => {
  const path = useLocation();
  return (
   <section>
{/* <div className=" bg-[#f19a37] relative isolate flex items-center gap-x-6 overflow-hidden  px-2 py-2.5 sm:px-3.5 z-50 ">
      <div className="flex  lg:ml-20">
        <div className=" leading-6  sm:flex sm:space-x-5">
          <a href="mailto:info@emech.pk" className=' cursor-pointer'>
            <div className=' sm:text-lg flex items-center  '>
            <CiMail className='w-8 h-8 mx-2 rounded-full  font-semibold  bg-[#f19a37] p-0.5' />info@emech.pk
            </div>
          </a>
          <a href="tel:+923227111989" className=' cursor-pointer '>
            <div className=' sm:text-lg flex items-center  '>
            <CiPhone className='w-8 h-8 mx-2 rounded-full  font-semibold  bg-[#f19a37] p-0.5' />+92 322 7111989
            </div>
          </a>
        </div>
      </div> 



      <div className="flex flex-1 justify-end lg:mr-20">
        <div className="-m-3 p-3 space-x-5 flex">
          <FaFacebookF onClick={()=>{console.log(path.pathname)}} className=" w-8 h-8 p-1 border border-[#fae14b] rounded-full" />
          <IoLogoInstagram className=" w-8 h-8 p-1 border border-[#fae14b] rounded-full" />
        </div>
      </div> 

    </div> */}





      


    <header className="h-20  flex items-center z-30 w-full dark:bg-gray-900  ">
        <div className="container mx-auto px-6 flex items-center justify-between">
        <Link to={"/"} className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="/images/logo.png" className="h-8" alt="Logo" />
            <div className="uppercase text-[#f19a37] font-black text-3xl">
            EMECH
            </div>
        </Link>
            
            <div className="flex items-center">
                <nav className="font-sen text-gray-800 dark:text-white uppercase text-lg lg:flex items-center hidden">
                    <Link to={"/"} className={`py-2 px-6 flex ${path.pathname == "/" ?'text-[#f19a37] font-bold' : ""}`}>
                        Home
                    </Link>
                    <Link to={"/about"} className={`py-2 px-6 flex ${path.pathname == "/about" ?'text-[#f19a37] font-bold' : ""}`}>
                        About
                    </Link>
                    <Link to={"/services"} className={`py-2 px-6 flex ${path.pathname == "/services" ?'text-[#f19a37] font-bold' : ""}`}>
                        Services
                    </Link>
                    <Link to={"/contact"} className={`py-2 px-6 flex ${path.pathname == "/contact" ?'text-[#f19a37] font-bold' : ""}`}>
                        Contact
                    </Link>
                </nav>
                <button className="lg:hidden flex flex-col ml-4">
                    <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1">
                    </span>
                    <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1">
                    </span>
                    <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1">
                    </span>
                </button>
            </div>
        </div>
    </header>

    
          </section>
    
  )
}

export default Navbar